import lozad from 'lozad'
import smoothscroll from 'smoothscroll-polyfill'

document.addEventListener('DOMContentLoaded', () => {
  // Find and hydrate all components
  for (const { selector, hydrate } of [
    require('components/Cards'),
    require('components/Filter'),
    require('components/Form'),
    require('components/Menu'),
    require('components/Member'),
    require('components/Sidebar')
  ]) {
    for (const element of document.querySelectorAll(selector)) {
      hydrate(element)
    }
  }

  // Lazyloading
  const lazys = document.querySelectorAll('img[data-lazyload=true]')
  for (const img of lazys) img.onload = () => img.setAttribute('data-decoded', true)
  lozad(lazys).observe()

  // Smooth anchors
  smoothscroll.polyfill()
  for (const a of document.querySelectorAll('a[href*="#"]')) {
    a.addEventListener('click', e => {
      const id = a.href.replace(window.location.origin + window.location.pathname, '').substr(1)

      if (id === 'top') {
        e.preventDefault()
        return window.scroll({ top: 0, left: 0, behavior: 'smooth' })
      }

      const target = document.getElementById(id)
      if (!target) return
      e.preventDefault()
      target.scrollIntoView({ behavior: 'smooth' })
    })
  }
})
