export const selector = '.filter'

export function hydrate (element) {
  const input = element.querySelector('input')
  const button = element.querySelector('button')

  const targetSelector = element.dataset.target
  const items = Array.from(document.querySelectorAll(targetSelector))
  for (const item of items) {
    item.dataset.filter = sanitize(item.innerText, { uppercase: true })
  }

  button?.addEventListener('click', clear)
  input?.addEventListener('input', filter)

  function clear () {
    input.value = ''
    filter()
  }

  function filter () {
    window.scrollTo(0, 0)
    const needle = sanitize(input.value, { uppercase: true })

    for (const item of items) {
      const match = needle && item.dataset.filter.includes(needle)
      item.style.display = (!needle || needle === '' || match) ? '' : 'none'

      // TODO improve performances
      // const text = item.querySelector('.card--member__name')
      // if (!text.dataset.withoutMark) text.dataset.withoutMark = text.innerText
      // text.innerHTML = text.dataset.withoutMark

      // if (!match) continue
      // text.innerHTML = sanitize(text.innerText).replace(new RegExp(needle, 'gi'), '<mark>$&</mark>')
    }
  }
}

function sanitize (string, { uppercase = false } = {}) {
  string = string
    .replace(/\u00a0/g, ' ')
    .replace(/\s+/g, ' ')
    .replace(/’/g, "'")

  if (string === ' ') return
  return uppercase ? string.toUpperCase() : string
}
