import AutoComplete from '@tarekraafat/autocomplete.js'

export const selector = '.menu'

export function hydrate (container) {
  const searchInput = container.querySelector('input[type="search"]')
  const toggleSearch = container.querySelector('#toggleSearch')
  toggleSearch.addEventListener('change', () => {
    if (toggleSearch.checked) searchInput.focus()
  })

  const autocomplete = new AutoComplete({
    selector: '#search',
    wrapper: false,
    data: {
      src: async () => {
        try {
          // Fetch External Data Source
          const source = await fetch('/index.json')
          const data = await source.json()
          return data
        } catch (error) {
          return error
        }
      },
      keys: ['title', 'authors'],
      filter: list => Array.from(new Set(list.map(value => value.match)))
        .map(q => list.find(value => value.match === q))
    },
    resultsList: {
      maxResults: 20,
      tag: 'div',
      class: 'autocomplete__results',
      element: (list, data) => {
        if (data.results.length) return
        const message = document.createElement('li')
        message.setAttribute('class', 'autocomplete__result')
        message.innerHTML = `Aucun résultat pour la recherche <b>${data.query}</b>`
        list.appendChild(message)
      },
      noResults: true
    },
    resultItem: {
      tag: 'a',
      class: 'autocomplete__result',
      element: (item, data) => {
        item.setAttribute('href', data.value.url)
        if (data.value.parent) item.dataset.parent = data.value.parent
      },
      highlight: 'autocomplete__highlight',
      selected: 'is-selected'
    },
    events: {
      input: {
        selection: event => {
          const result = event.detail.selection?.value
          if (result) window.location.href = result.url
        }
      }
    }
  })
}
