export const selector = '.sidebar'

const lastOf = arr => arr[arr.length - 1]

export function hydrate (element) {
  // Toggle sidebar by cliking inside it
  const toggleSidebar = element.querySelector('input#toggleSidebar')
  element.addEventListener('click', e => {
    toggleSidebar.checked = !toggleSidebar.checked
  })

  // Highlight TOC
  const links = Array.from(element.querySelectorAll('a[href]'))
  const targets = []
  for (const link of links) {
    const anchor = link.href.replace(window.location.origin + window.location.pathname, '')
    const target = anchor.startsWith('#') && document.querySelector(anchor)
    if (!target) continue

    targets.push({ target, link })
  }

  spy()
  window.addEventListener('scroll', spy)

  function spy () {
    window.requestAnimationFrame(() => {
      // Remove all
      for (const { link } of targets) {
        link.parentNode.classList.remove('is-active')
      }

      // Add last visible
      for (const { target, link } of targets) {
        const { top } = target.getBoundingClientRect()
        const active = top >= 0 && top < document.documentElement.scrollTop + window.innerHeight
        if (active) {
          link.parentNode.classList.add('is-active')
          return
        }
      }

      // If none, add last
      lastOf(targets)?.link.parentNode.classList.add('is-active')
    })
  }
}
