export const selector = '.form'

export function hydrate (container) {
  const anchor = '#' + (container.dataset.anchor ?? '')
  container.setAttribute('action', window.location.href + anchor)

  for (const textarea of container.querySelectorAll('textarea')) {
    const resize = () => {
      textarea.style.height = 'auto'
      textarea.style.height = (textarea.scrollHeight) + 'px'
    }

    resize()
    textarea.addEventListener('input', resize, false)
  }
}
