export const selector = '.member'

export function hydrate (container) {
  // Scroll matching sidebar name into view
  const sidebar = document.querySelector('.sidebar')
  const entry = sidebar?.querySelector('.is-active')
  if (!entry) return
  console.log(entry)

  sidebar.scrollTop = entry.offsetTop - 20
}
